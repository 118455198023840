@use 'variables' as *;

.search {
  .search-header {
    background-color: $white;
    padding: clamp(3rem, 5vw, 4rem) 0;
    margin-bottom: 4rem;

    .search-title {
      color: $black;
      font-family: $font-family-heading;
      font-weight: $font-weight-regular;
      font-size: var(--wp--preset--font-size--h-2);
      margin-bottom: 1.5rem;
      text-align: center;
    }

    .search-form {
      max-width: 800px;
      margin: 0 auto;
      position: relative;

      input[type="search"] {
        width: 100%;
        padding: 1rem 1.5rem;
        font-size: var(--wp--preset--font-size--paragraph);
        border: none;
        background: $dark-gray;
        color: $white;

        &:focus {
          outline: 2px solid $yellow-orange;
        }
      }

      button {
        position: absolute;
        right: 0.5rem;
        top: 50%;
        transform: translateY(-50%);
        background: none;
        border: none;
        padding: 0.5rem;
        cursor: pointer;
        color: $white;

        &:hover {
          color: $yellow-orange;
        }

        svg {
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    }
  }

  .search-results {
    padding: 0 0 4rem;

    .no-results {
      text-align: center;
      padding: 3rem 0;

      h2 {
        font-family: $font-family-heading;
        font-weight: $font-weight-regular;
        font-size: var(--wp--preset--font-size--h-3);
        color: $white;
        margin-bottom: 1rem;
      }

      p {
        font-size: var(--wp--preset--font-size--paragraph);
        max-width: 600px;
        margin: 0 auto 2rem;
      }
    }

    .result-item {
      margin-bottom: 2.5rem;
      padding-bottom: 2.5rem;
      border-bottom: 1px solid $yellow-orange;

      &:last-child {
        border-bottom: none;
      }

      .result-title {
        font-family: $font-family-heading;
        font-size: var(--wp--preset--font-size--h-5);
        font-weight: $font-weight-regular;
        margin-bottom: 1rem;

        a {
          color: $white;
          text-decoration: none;
          transition: color 0.3s ease;

          &:hover {
            color: $yellow-orange;
          }
        }
      }

      .result-meta {
        font-size: 0.9rem;
        color: $yellow-orange;
        margin-bottom: 1rem;

        span {
          &:not(:last-child)::after {
            content: "•";
            margin: 0 0.5rem;
          }
        }
      }

      .result-excerpt {
        font-size: var(--wp--preset--font-size--paragraph);
        margin-bottom: 1rem;

        p {
          margin: 0;
        }
      }

      .read-more {
        display: inline-flex;
        align-items: center;
        gap: 0.5rem;
        font-family: $font-family-heading;
        font-weight: $font-weight-regular;
        color: $white;
        text-decoration: none;
        transition: all 0.3s ease;

        &:hover {
          color: $yellow-orange;
          transform: translateX(5px);
        }

        svg {
          width: 1.25rem;
          height: 1.25rem;
        }
      }
    }
  }

  .search-pagination {
    padding: 2rem 0;

    .pagination {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      list-style: none;
      margin: 0;
      padding: 0;

      li {

        a,
        span {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          min-width: 2.5rem;
          height: 2.5rem;
          padding: 0 0.5rem;
          font-family: $font-family-heading;
          text-decoration: none;
          transition: all 0.3s ease;
        }

        a {
          background-color: $yellow-orange;
          color: $dark-gray;

          &:hover {
            background-color: $picton-blue;
            transform: translateY(-2px);
          }
        }

        &.current span {
          background-color: $picton-blue;
          color: $dark-gray;
        }

        &.disabled {
          opacity: 0.5;
          pointer-events: none;
        }
      }
    }
  }
}