@use 'variables' as *;

.error404 {
  .error-content {
    min-height: 50vh;
    padding: clamp(3rem, 5vw, 6rem) 0;
    text-align: center;

    .error-code {
      font-family: $font-family-heading;
      font-size: clamp(6rem, 15vw, 12rem);
      font-weight: $font-weight-regular;
      line-height: 1;
      color: $white;
      margin-bottom: 1rem;
    }

    .error-message {
      font-size: var(--wp--preset--font-size--h-3);
      font-family: $font-family-heading;
      font-weight: $font-weight-regular;
      color: $white;
      margin-bottom: 2rem;
    }

    .error-description {
      font-size: var(--wp--preset--font-size--paragraph);
      max-width: 600px;
      margin: 0 auto 3rem;
      color: $white;
    }

    .back-home {
      display: inline-flex;
      align-items: center;
      gap: 0.5rem;
      padding: 1rem 2rem;
      background-color: $yellow-orange;
      color: $dark-gray;
      text-decoration: none;
      border-radius: 0;
      font-family: $font-family-heading;
      font-weight: $font-weight-medium;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      transition: all 0.3s ease;

      &:hover {
        background-color: $picton-blue;
        transform: translateY(-2px);
      }

      svg {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
}