@use 'variables' as *;

$header-height-desktop: 120px;
$header-height-mobile: 140px;

.site-header {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba($black, 0.5);
  transition: all 0.3s ease;
  font-family: $font-family-heading;

  &.hide {
    transform: translateY(-100%);
  }

  &.header--relative {
    position: relative;
  }
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1600px;
  margin: 0 auto;
  height: $header-height-desktop;
  transition: var(--transition);
  font-size: 24px;
}

.logo-container {
  flex: 0 0 auto;

  img {
    height: auto;
    width: 220px;
    transition: var(--transition);
    padding: 12px 0;
  }
}

.main-navigation {
  height: 100%;
  display: flex;
  align-items: stretch;

  ul.menu {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    justify-content: flex-end;
    align-items: stretch;
    height: 100%;

    > li {
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;

      &:last-child {
        margin-right: 0;
        margin-left: 2rem;
        
        a {
          background: var(--wp--preset--color--picton-blue);
          padding: 12px 24px;
          border: 1px solid white;
          height: auto;
          
          &::after {
            display: none;
          }

          &:hover {
            background: $yellow-orange;
            color: $black;
            border-color: $yellow-orange;
          }

        }
      }

      &:not(:last-child).current-menu-item {
        background-color: var(--wp--preset--color--picton-blue);
        padding: 0;
        height: 100%;
        display: flex;
        align-items: center;
        
        > a {
          font-weight: 600;
          height: 100%;
          display: flex;
          align-items: center;
          
          &::after {
            display: none;
          }
        }
      }

      a {
        color: $white;
        text-decoration: none;
        font-weight: $font-weight-regular;
        text-transform: uppercase;
        padding: 0 0;
        height: 100%;
        transition: var(--transition);
        position: relative;
        display: flex;
        align-items: center;
        padding: 0 25px;

        .submenu-indicator {
          margin-left: 5px;
          font-size: 0.7em;
          transition: transform 0.3s ease;
        }

        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 2px;
          background-color: $white;
          transform: scaleX(0);
          transition: transform 0.3s ease;
        }

        &:hover,
        &:focus {
          color: var(--header-hover);

          &::after {
            transform: scaleX(1);
          }

          .submenu-indicator {
            transform: rotate(180deg);
          }
        }
      }

      .sub-menu {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        background-color: $black;
        padding: 0;
        min-width: 200px;
        white-space: nowrap;
        list-style: none;
        margin: 0;

        li {
          margin: 0;

          a {
            display: block;
            padding: 10px 20px;
            text-transform: uppercase;
            font-weight: $font-weight-regular;
            color: $white;
            transition: background-color 0.3s ease, color 0.3s ease;

            &:hover {
              background-color: $yellow-orange;
              color: $black;
            }
          }
        }
      }

      &:hover .sub-menu {
        display: block;
        animation: fadeIn 0.3s ease;
      }
    }
  }

  ul.menu.menu--right {
    width: 100%;
    justify-content: flex-end;
  }

  .submenu-toggle {
    background: none;
    border: none;
    padding: 5px;
    margin-left: 5px;
    cursor: pointer;
    color: inherit;
    font-size: 0.7em;
    transition: transform 0.3s ease;

    &:hover {
      opacity: 0.8;
    }
  }

  .menu-item-has-children.active .submenu-toggle {
    transform: rotate(180deg);
  }

  @media (max-width: $breakpoint-md) {
    .submenu-toggle {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      padding: 10px;
      font-size: 1.2em;
    }

    .menu-item-has-children {
      position: relative;

      &.active .submenu-toggle {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }
}

.mobile-menu-toggle {
  display: none;
  border: none;
  cursor: pointer;
  padding: 10px 30px ;
  background: $yellow-orange;
  border-radius: 38pt;
  margin: 30px 40px 0 auto;

  span {
    display: block;
    width: 35px;
    height: 4px;
    background-color: $black;
    transition: var(--transition);
    margin-bottom: 4px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &:hover span {
    background-color: var(--header-hover);
  }
}

@media (max-width: $breakpoint-xl) {
  .header-container {
    height: unset;
    flex-wrap: wrap;
    justify-content: center;
  }

  .logo-container {
    flex: 1 1 100%;
    text-align: center;
  }

  .main-navigation {
    display: none;

    ul.menu {
      flex-direction: column;
      align-items: center;

      li {
        margin: 10px 0;
        width: 100%;
        height: auto;
        
        &.current-menu-item {
          background: none;
          height: auto;
          margin: 10px 0;
          padding: 0;
          
          > a {
            background: var(--wp--preset--color--picton-blue);
            height: auto;
            padding: 12px 24px;
          }
        }

        a {
          justify-content: center;
          height: auto;
          padding: 12px 24px;

          .submenu-indicator {
            transition: transform 0.3s ease;
          }
        }

        > li:last-child a {
          margin-top: 10px;
        }

        .sub-menu {
          display: none;
          position: static;
          background-color: transparent;
          padding: 0;
          text-align: center;
          border: none;

          li {
            margin: 5px 0;
            
            a {
              text-align: center;
              padding: 8px 24px;
            }
          }
        }

        &.active>a .submenu-indicator {
          transform: rotate(180deg);
        }
      }
    }
  }

  .mobile-menu-toggle {
    display: block;
  }
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

body {
  padding-top: 0;
}