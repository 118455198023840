@use 'variables' as *;

// Layout styles
.content-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.alignwide {
  max-width: 1400px;
}

.site-header,
.site-footer {
  line-height: 1;
  background-color: $dark-gray;
  color: white;
}

.site-main {
  padding: 0;
  position: relative;
  z-index: 1;
}

@media (min-width: 1400px) {
  .alignwide {
    margin-left: calc((1200px - 1400px) / 2);
    margin-right: calc((1200px - 1400px) / 2);
  }
}

body {
  padding-top: 0;
}