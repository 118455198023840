@use 'variables' as *;

.site-footer {
  background-color: #4A4A4A;
  color: #ffffff;
  padding: 3rem 0 0;
  font-size: clamp(1rem, 0.1111rem + 1.8519vw, 1.5rem);

  .footer-container {
    max-width: 1200px;
    margin: 0 auto;
  }

  .footer-content {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 2rem;
    margin-bottom: 2rem;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
      text-align: center;
    }
  }

  h3 {
    font-size: clamp(1.25rem, 0.1389rem + 2.3148vw, 1.875rem);
    font-weight: 600;
    margin: 0;
    color: #ffffff;
  }

  .footer-left {
    .footer-address {
      margin-bottom: 1.5rem;
    }

    .footer-social-media {
      display: flex;
      gap: 1rem;
      margin-bottom: 1.5rem;

      @media (max-width: 768px) {
        justify-content: center;
      }

      a {
        color: $black;
        font-size: clamp(1.25rem, -0.9722rem + 4.6296vw, 2.5rem);
        transition: color 0.3s ease;
        width: clamp(2.25rem, -0.8611rem + 6.4815vw, 4rem);
        height: clamp(2.25rem, -0.8611rem + 6.4815vw, 4rem);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: $yellow-orange;
        text-decoration: none;

        &:hover {
          color: $white;
        }
      }
    }

    .footer-contact, .footer-address {
      div {
        margin-bottom: 0.5rem;
      }

      a {
        color: #ffffff;
        text-decoration: none;
        transition: color 0.3s ease;

        &:hover {
          color: #F7A928;
        }
      }
    }
  }

  .footer-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @media (max-width: 768px) {
      align-items: center;
    }

    .footer-navigation {
      margin-bottom: 2rem;
      width: 100%;

      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between; // changed from flex-end
        gap: 1.5rem;

        @media (max-width: 768px) {
          display: grid;
          justify-content: center;
        }
      }

      a {
        color: #ffffff;
        text-decoration: underline;
        text-underline-offset: 0.5rem;
        text-decoration-thickness: 0.0625rem;
        text-decoration-color: $picton-blue;
        transition: color 0.3s ease;
        font-size: clamp(1.125rem, 0.4583rem + 1.3889vw, 1.5rem);

        &:hover {
          color: $yellow-orange;
          text-decoration-color: $yellow-orange;
          // text-underline-offset: 0.125rem;
          text-decoration-thickness: 0.125rem;
          transition: color 0.3s ease, text-underline-offset 0.3s ease, text-decoration-thickness 0.3s ease;
        }
      }
    }

    .footer-logo {

      img {
        width: auto;
        height: clamp(5.3125rem, -7.4653rem + 26.6204vw, 12.5rem);
      }
    }
  }

  .footer-copyright {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding: .5rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.875rem;

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 1rem;
      text-align: center;
    }

    a {
      color: #ffffff;
      text-decoration: none;
      transition: color 0.3s ease;

      &:hover {
        color: #F7A928;
      }
    }
  }
}