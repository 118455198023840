@use '../variables' as *;

.wp-block-button {
  &__link {
    display: inline-block;
    padding: 10px 20px;
    text-decoration: none;
    transition: var(--transition);
    font-weight: $font-weight-semi-bold;
    background-color: transparent;
    color: $white;
    border: 0.25rem solid $picton-blue;
    text-transform: uppercase;

    &:hover,
    &:focus {
      background-color: $picton-blue;
      color: $black !important;
      transform: translateY(-2px);
    }

    &:active {
      transform: translateY(1px);
    }
  }
}

// Adaptations for different background colors
.has-picton-blue-background-color .wp-block-button__link {
  border: 0.25rem solid $white;

  &:hover,
  &:focus {
    background-color: $white;
  }
}

.has-white-background-color .wp-block-button__link,
.has-light-gray-background-color .wp-block-button__link {
  color: $black;
}