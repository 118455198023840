@use 'variables' as *;

// Default text colors
body {
  --text-color: var(--wp--preset--color--white, #fff);
  --link-color: var(--wp--preset--color--picton-blue, #1EB7EE);
  --link-hover-color: var(--wp--preset--color--yellow-orange, #FAAF40);

  color: var(--text-color);
}

a {
  color: var(--link-color);
  transition: color 0.3s ease;

  &:hover,
  &:focus {
    color: var(--link-hover-color);
  }
}

// Text color adaptations for different backgrounds

// Apply text colors to specific elements
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
dl,
table {
  color: var(--text-color);
}

// Classic Editor specific styles
#tinymce {
  background-color: #fff !important;
  color: #000 !important;
  padding: 10px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    color: #000 !important;
  }
}


// Additional styles for better contrast in specific scenarios
.has-white-background-color,
.has-light-gray-background-color {

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  .gform-field-label,
  .gform_button,
  .content {
    color: var(--wp--preset--color--black, $black);
  }
}

.has-picton-blue-background-color {
  a {
    color: var(--wp--preset--color--white, $white);

    &:hover,
    &:focus {
      color: var(--wp--preset--color--dark-gray, $dark-gray);
    }
  }
}

.has-yellow-orange-background-color {
  a {
    color: var(--wp--preset--color--black, $black);

    &:hover,
    &:focus {
      color: var(--wp--preset--color--dark-gray, $dark-gray);
    }
  }
}