.alignwide,
.alignfull {
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  max-width: 100vw;
  width: 100vw;
}

.alignwide {
  max-width: var(--wp--style--global--wide-size);
}

@media (min-width: 1400px) {
  .alignwide {
    margin-left: calc((1200px - 1400px) / 2);
    margin-right: calc((1200px - 1400px) / 2);
    max-width: 1400px;
    width: auto;
  }
}

.entry-content > .alignwide {
  max-width: var(--wp--style--global--wide-size);
}

.entry-content > .alignfull {
  max-width: none;
}
