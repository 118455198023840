@use 'variables' as *;

.accommodations-list {
    background-color: $light-gray;
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    padding: 0 0 40px;
    color: $black !important;

    .container {
        max-width: 1600px;
        margin: 0 auto;
        padding: 40px 20px 0;
    }
}

.accommodations-archive {
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
}

.room-select {
    width: 200px;
    padding: 8px;
    margin-bottom: 40px;
    border: 1px solid $dark-gray;
    background-color: $white;
}

.accommodation {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    margin-bottom: 40px;
    transition: opacity 0.3s ease, transform 0.3s ease;
    opacity: 1;
    transform: translateY(0);

    @media (max-width: 767px) {
        flex-direction: column !important;
    }

    &-reverse {
        flex-direction: row-reverse;
    }
}

.room-info {
    flex: 1;
    min-width: 300px;

    @media (max-width: 767px) {
        order: 2; // Move info below image
    }

    h2 {
        color: #000;
        font-size: var(--wp--preset--font-size--h-3);
        font-weight: bold;
        margin-bottom: 20px;
        padding-bottom: 10px;
        border-bottom: 2px solid $picton-blue;
    }
}

.room-image {
    flex: 1;
    min-width: 300px;
    position: relative;
    aspect-ratio: 4/3;

    @media (max-width: 767px) {
        order: 1; // Move image to top
    }

    .gallery {
        height: 100%;
        background: none;

        &-cell {
            width: 100%;
            height: 100%;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    // Customize Flickity
    .flickity-button {
        background: rgba(255, 255, 255, 0.75);
        width: 40px;
        height: 40px;
        transition: all 0.3s ease;

        &:hover {
            background: white;
        }

        &:disabled {
            opacity: 0;
        }
    }

    .flickity-prev-next-button {
        &.previous { left: 10px; }
        &.next { right: 10px; }
    }

    .flickity-page-dots {
        bottom: 10px;

        .dot {
            width: 12px;
            height: 12px;
            opacity: 1;
            background: rgba(255, 255, 255, 0.5);
            border: 2px solid white;
            transition: all 0.3s ease;

            &.is-selected {
                background: $picton-blue;
                transform: scale(1.2);
            }
        }
    }

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.features {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    flex-wrap: wrap; // Allow wrapping
    overflow-x: visible; // Remove horizontal scroll
    padding-bottom: 5px; // Add padding to ensure scrollbar doesn't cover content
    -webkit-overflow-scrolling: touch; // Smooth scrolling on iOS

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
        display: none;
    }
    
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.feature {
    display: inline-flex;
    align-items: center;
    gap: 5px;
    color: $black;
    flex-shrink: 0;
    white-space: nowrap;
    font-size: clamp(1rem, 0.1111rem + 1.8519vw, 1.5rem);
    font-weight: bold;

    i {
        color: $black;
        font-size: 20px;
        margin-right: 5px;
        background-color: $white;
        padding: 5px;
    }
}

.description {
    line-height: 1.6;
    margin-bottom: 20px;

    p {
        color: #000;
    }
}

.book-now {
    display: inline-block;
    padding: 10px 20px;
    text-decoration: none;
    transition: var(--transition);
    font-weight: $font-weight-semi-bold;
    background-color: transparent;
    color: $black;
    border: 0.25rem solid $picton-blue;
    text-transform: uppercase;

    &:hover,
    &:focus {
      background-color: $picton-blue;
      color: $black !important;
      transform: translateY(-2px);
    }

    &:active {
      transform: translateY(1px);
    }
}