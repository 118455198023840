:root {
  --header-text: #fff;
  --header-hover: $yellow-orange;
  --cta-bg: #fff;
  --transition: all 0.3s ease;
}

// Colors
$picton-blue: #1EB7EE;
$yellow-orange: #FAAF40;
$white: #fff;
$black: #000;
$light-gray: #E3E2E0;
$dark-gray: #575757;

// Typography
$font-family-heading: var(--wp--preset--font-family--inter);
$font-family-body: var(--wp--preset--font-family--inter);
$font-size-base: var(--wp--preset--font-size--paragraph);

// Font weights
$font-weight-thin: 100;
$font-weight-extra-light: 200;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;
$font-weight-black: 900;

// Breakpoints
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;