@use 'variables' as *;

.off-canvas-menu {
    position: fixed;
    top: 0;
    right: 0;
    width: 300px;
    height: 100%;
    background-color: var(--wp--preset--color--dark-gray);
    color: var(--header-text);
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out; // Smoother transition
    z-index: 1000;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
    font-family: $font-family-body; // Use the body font
  
    &.active {
      transform: translateX(0);
    }
  
    .close-menu {
      position: absolute;
      top: 15px;
      right: 15px;
      border: none;
      cursor: pointer;
      color: $black;
      background: $yellow-orange;
      border-radius: 50%;
  
      svg {
        width: 40px;
        height: 40px;
        fill: $black;
      }
    }
  
    .mobile-navigation {
      padding: 30px;
  
      ul.menu {
        list-style: none;
        padding: 0;
        margin: 0;
  
        li {
          margin-bottom: 15px; // Add spacing between menu items
  
          a {
            color: var(--header-text);
            text-decoration: none;
            font-size: 1.1rem; // Slightly larger font size
            display: block;
            padding: 10px 0;
            transition: color 0.3s ease;
  
            &:hover {
              color: var(--header-hover);
            }
          }
  
          &:not(.menu-item-has-children):last-child a {
            background: var(--wp--preset--color--picton-blue);
            padding: 12px 24px;
            border: 1px solid white;
            height: auto;
            text-align: center;
          }
        }
      }
    }
  }
  
  .menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 999;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.4s ease-in-out;
  
    &.active {
      visibility: visible;
      opacity: 1;
    }
  }