@use '../variables' as *;

.contact-info {
  display: flex;
  gap: 2rem;
  padding: 1.5rem;

  .contact-column {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &:first-child {
      align-items: flex-end;
      text-align: right;
    }

    &:last-child {
      align-items: flex-start;
      text-align: left;
    }
  }

  .contact-item {
    display: flex;
    align-items: center;
    gap: 1rem;

    .icon {
      flex-shrink: 0;
      width: 3.75rem;
      height: 3.75rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $picton-blue;
      border-radius: 50%;

      svg {
        width: 2rem;
        height: 2rem;
        stroke: #000000;
        display: block; /* Ensure SVG is treated as a block element */
        margin: auto;
      }
    }

    .content {
      font-size: var(--wp--preset--font-size--paragraph);

      &.phone {
        font-size: var(--wp--preset--font-size--h-6);
        font-weight: $font-weight-bold;
      }

      &.email {
        font-size: calc(var(--wp--preset--font-size--h-6) - 10%);
        font-weight: $font-weight-semi-bold;
      }

      .label {
        font-size: 0.875rem;
        font-weight: $font-weight-medium;
        color: $dark-gray;
        margin-bottom: 0.25rem;
      }

      a {
        color: inherit;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  @media (max-width: $breakpoint-md) {
    flex-direction: column;
    gap: 1.5rem;

    .contact-column {
      width: 100%;

      &:first-child,
      &:last-child {
        align-items: center;
        text-align: center;
      }
    }

    .contact-item {
      flex-direction: column;

      .icon {
        width: 5rem;
        height: 5rem;

        svg {
          width: 2.5rem;
          height: 2.5rem;
        }
      }
    }
  }

  @media (max-width: 480px) {
    padding: 1rem;

    .contact-item {
      gap: 0.5rem;

      .icon {
        width: 4rem;
        height: 4rem;

        svg {
          width: 2rem;
          height: 2rem;
        }
      }
    }
  }
}
