@use '../variables' as *;

.wp-block-group {
  &:not(:first-child) {
    margin-top: 2rem; // Adjust this value as needed

    @media (min-width: $breakpoint-md) {
      margin-top: 3rem; // Larger margin on larger screens
    }
  }

  &.has-background {
    padding: 0 15px;
  }

  // Additional group block styles can be added here
}