// Forms
// -------------------
@use 'variables' as *;

// Create a placeholder selector for form styles
%base-form {
  max-width: 1000px;
  margin: 0 auto;

  // Labels
  .gfield_label {
    display: block;
    font-family: $font-family-heading;
    font-weight: $font-weight-regular;
    font-size: var(--wp--preset--font-size--h-6);
    
    margin-bottom: 0.5rem;
  }

  // Text inputs and textareas
  input[type="text"],
  input[type="email"],
  input[type="url"],
  input[type="password"],
  input[type="search"],
  input[type="number"],
  input[type="tel"],
  input[type="date"],
  textarea {
    width: 100%;
    background-color: var(--input-bg);
    border: 3px solid var(--input-border);
    color: var(--input-text);
    transition: all 0.3s ease;

    &::placeholder {
      color: var(--placeholder-text);
      opacity: 0.7;
    }

    &:focus {
      outline: none;
      border-color: var(--input-focus-border);
      box-shadow: 0 0 0 3px rgba(49, 53, 57, 0.1);
    }
  }

  // Textareas
  textarea {
    min-height: 150px;
    resize: vertical;
  }

  // Select dropdowns
  select {
    width: 100%;
    background-color: var(--input-bg);
    border: 3px solid var(--input-border);
    transition: all 0.3s ease;

    &:focus {
      outline: none;
      border-color: var(--input-focus-border);
      box-shadow: 0 0 0 3px rgba(49, 53, 57, 0.1);
    }
  }

  // Submit buttons
  button[type="submit"],
  input[type="submit"] {
    display: inline-block;
    padding: 10px 20px;
    text-decoration: none;
    transition: var(--transition);
    font-weight: $font-weight-semi-bold;
    background-color: transparent;
    border: 0.25rem solid $picton-blue;
    text-transform: uppercase;

    &:hover,
    &:focus {
      background-color: $picton-blue;
      color: $black !important;
      transform: translateY(-2px);
    }

    &:active {
      transform: translateY(1px);
    }
  }
}

// Apply the placeholder to specific form classes
.gform_wrapper.gravity-theme {
  .form,
  form {
    --input-bg: var(--wp--preset--color--white);
    --input-border: var(--wp--preset--color--black);
    --input-text: var(--wp--preset--color--black);
    --input-focus-border: var(--wp--preset--color--yellow-orange);
    --label-text: $picton-blue;
    --placeholder-text: var(--wp--preset--color--black);

    @extend %base-form;
  }

  // Password protected post form
  .password-form {
    @extend %base-form;

    max-width: 500px;
    text-align: center;

    .password-box {
      max-width: 300px;
      margin: 1rem auto;
    }

    .password-btn {
      margin-top: 1rem;
    }
  }
}