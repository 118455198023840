@use '../variables' as *;

.wp-block-media-text.is-style-diagonal-background {
  &.is-stacked-on-mobile {
    @media (max-width: 600px) {
      grid-template-areas:
        "media-text-content"
        "media-text-media" !important;
      
      .wp-block-media-text__media {
        grid-area: media-text-media;
      }
      
      .wp-block-media-text__content {
        grid-area: media-text-content;
      }
    }
  }
}

.wp-block-media-text.is-style-diagonal-background .wp-block-media-text__content {
  height: 100%;
  background: linear-gradient(-45deg,
      transparent calc(50% - 100px),
      rgba($picton-blue, 0.1) calc(50% - 100px),
      rgba($picton-blue, 0.1) calc(50% + 100px),
      transparent calc(50% + 100px));
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}