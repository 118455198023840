@use 'variables' as *;

.pagination-block {
  margin: 2rem 0;
  display: flex;
  justify-content: center;
}

.pagination {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  justify-content: center;
  padding: 0;
  list-style: none;
  margin: 0;

  li {
    margin: 0;
    
    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    &.current {
      span {
        background-color: $picton-blue;
        color: $black;
        border-color: $picton-blue;
        font-weight: $font-weight-bold;
      }
    }
  }

  a, button, span {
    display: inline-block;
    padding: 8px 12px;
    text-decoration: none;
    transition: var(--transition);
    font-weight: $font-weight-semi-bold;
    background-color: transparent;
    color: $white;
    border: 2px solid $picton-blue;
    min-width: 40px;
    text-align: center;
    
    &:hover,
    &:focus {
      background-color: $picton-blue;
      color: $black;
      transform: translateY(-2px);
    }
    
    &:active {
      transform: translateY(1px);
    }
  }

  .btn {
    a, button {
      text-transform: uppercase;
      padding: 8px 15px;
    }
  }
  
  @media (max-width: $breakpoint-md) {
    gap: 0.25rem;
    
    a, button, span {
      padding: 6px 10px;
      min-width: 36px;
      font-size: 0.9rem;
    }
    
    .first, .last {
      display: none;
    }
  }
}

// Adaptations for different background colors
.has-picton-blue-background-color .pagination {
  a, button, span {
    border-color: $white;
    color: $white;
    
    &:hover,
    &:focus {
      background-color: $white;
      color: $black;
    }
  }
  
  li.current span {
    background-color: $white;
    border-color: $white;
    color: $black;
  }
}

.has-white-background-color .pagination,
.has-light-gray-background-color .pagination {
  a, button, span {
    color: $black;
  }
}
