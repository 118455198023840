@use 'variables' as *;

.hero {
  position: relative;
  width: 100%;
  height: 80vh;
  max-height: 800px;
  overflow: hidden;
  background-color: #000;
  z-index: -1;

  &-video {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    height: 0;
    overflow: hidden;

    iframe {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      transform: translate(-50%, -50%);
      pointer-events: none;
      background-position: center;
      background-repeat: no-repeat;
    }

    @media (min-aspect-ratio: 16/9) {
      iframe {
        height: 56.25vw;
      }
    }

    @media (max-aspect-ratio: 16/9) {
      iframe {
        width: 177.78vh;
      }
    }

    @media (max-width: 768px) {
      height: 600px;
      padding-bottom: 0;

      iframe {
        width: 177.78vh;
        height: 600px;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        object-fit: cover;
        min-width: 100%;
      }
    }

    @media (max-width: 480px) {
      height: 600px;
      padding-bottom: 0;
    }
  }

  &-image {
    height: 100vh;
    max-height: 1080px;
    width: 100%;
    position: relative;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    @media (max-width: 768px) {
      height: 600px;
      max-height: none;
    }
  }
}

.hero-container {
    position: relative; // Establish positioning context
    overflow: hidden; // Clip the button within the container
}

.scroll-to-content {
    position: absolute; // Changed from sticky to absolute
    bottom: 20px; // Align to the bottom of the container
    left: 50%;
    transform: translateX(-50%);
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    width: 40px;
    height: 40px;
    border-radius: 50%; // Make it a circle
    display: flex; // Use flexbox for centering the icon
    align-items: center; // Vertically center the icon
    justify-content: center; // Horizontally center the icon
    cursor: pointer;
    z-index: 10;
    // margin-bottom: -40px; // Reset any potential margin

    i {
        font-size: 20px;
    }

    // Add a media query for smaller screens if needed
    @media (max-width: 768px) {
        bottom: 10px; // Adjust bottom spacing for smaller screens
    }
}

// Adjust space below hero
.has-hero .site-main {
  margin-top: 2rem;
}

.no-hero .site-main {
  margin-top: 8rem;
}