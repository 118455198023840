// Typography styles
@use 'variables' as *;

body {
  font-family: $font-family-body;
  font-size: $font-size-base;
  font-weight: $font-weight-regular;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: "opsz" 14;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-heading;
  margin-bottom: 0.5em;
  font-weight: $font-weight-regular;
  font-optical-sizing: auto;
  font-style: normal;
}

h1 {
  font-size: var(--wp--preset--font-size--h-1);
}

h2 {
  font-size: var(--wp--preset--font-size--h-2);
}

h3 {
  font-size: var(--wp--preset--font-size--h-3);
}

h4 {
  font-size: var(--wp--preset--font-size--h-4);
}

h5 {
  font-size: var(--wp--preset--font-size--h-5);
}

h6 {
  font-size: var(--wp--preset--font-size--h-6);
}

p {
  font-size: var(--wp--preset--font-size--paragraph);
}

strong {
  font-weight: $font-weight-bold;
}

em {
  font-style: italic;
}

.article-h1 {
  font-family: $font-family-heading;
  font-size: var(--wp--preset--font-size--h-1);
  font-weight: $font-weight-bold;
  font-optical-sizing: auto;
  font-style: normal;
  text-align: center;
}

// Utility mixin for Inter
@mixin inter($weight: $font-weight-regular, $style: normal, $opsz: 14) {
  font-family: $font-family-body;
  font-optical-sizing: auto;
  font-weight: $weight;
  font-style: $style;
  font-variation-settings: "opsz" $opsz;
}

// Utility classes for Inter
.inter-light {
  @include inter($font-weight-light);
}

.inter-regular {
  @include inter($font-weight-regular);
}

.inter-medium {
  @include inter($font-weight-medium);
}

.inter-bold {
  @include inter($font-weight-bold);
}

.inter-italic {
  @include inter($font-weight-regular, italic);
}

// Example of using optical sizing for different contexts
.small-text {
  @include inter($font-weight-regular, normal, 14);
}

.large-text {
  @include inter($font-weight-regular, normal, 32);
}