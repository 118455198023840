/* Custom Gallery Block Style */
@use '../variables' as *;

.wp-block-gallery {
  @media (max-width: 600px) {
    flex-direction: column;

    .wp-block-image {
      width: calc((267/363) * 100%) !important;
      margin: 0 auto !important;
    }
  }
}
