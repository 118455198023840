/* Theme Name: Lake Devavan Resort 2025
 * Description: A modern WordPress theme for Delavan Lake Resort
 * Author: Vector & Ink
*/

@use 'variables' as *;

// Core styles
@use 'alignments';
@use 'typography';
@use 'layout';
@use 'header';
@use 'footer';
@use 'hero';
@use 'forms';
@use 'error-page';
@use 'search';
@use 'text-colors';
@use 'off-canvas';
@use 'accommodations';
@use 'pagination';

// Block styles
@use 'blocks/group';
@use 'blocks/mediatext';
@use 'blocks/gallery';
@use 'blocks/buttons';
@use 'blocks/contact-info';
@use 'blocks/carousel';

body {
  background-color: var(--wp--preset--color--dark-gray);
  overflow-x: hidden;
}